import React, { useState } from 'react';
import './table.css';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectCells, selectColumns, selectNear, setCells  } from '../../store/taskReducerSlice';
import Footer from "./Footer";
import { average } from '../../helpers/average';

const Table = () => {
  const dispatch = useDispatch();

  const cells = useSelector(selectCells);

  const history = useHistory();

  const [percent, setPercent] = useState('');
  const [activ, setActiv] = useState('');
  const [nearest, setNearest] = useState('');
  const columns = useSelector(selectColumns);
  const near = useSelector(selectNear);

  const onDelete = (id) => {
    let newCells = [
    ...cells.slice(0, +id), ...cells.slice(+id + 1),
    ];
    newCells = [...newCells.slice(0, -1)];

    const averageCells = average(newCells);
    dispatch(setCells([...newCells, averageCells]));
    };

    const incr = (text) => {
      let id = text.split('c')[0];
      let incrId = +text.split('c')[1];
      let incrCells = [
        ...cells.slice(+id, +id + 1)
       ];

      let incrItems = {};
      function incrRows(cells){
        const cloneCells = JSON.parse(JSON.stringify(cells));
        if(cloneCells[0]){
        let column = Object.values(cloneCells[0]);
        for (let i = 0; i < column.length; i++) {
          if (i === incrId) {
            ++cloneCells[0][incrId]
          }
          incrItems[i] = cloneCells[0][i];
        }
       }
      }
      incrRows(incrCells);

      let newCells = [
       ...cells.slice(0, +id), incrItems, ...cells.slice(+id + 1),
      ];
      newCells = [...newCells.slice(0, -1)];

      const averageCells = average(newCells);
      dispatch(setCells([...newCells, averageCells]));
    };


  const renderCell = (cell, i) => {
    let cellVal = Object.values(cell);
    let result = cellVal.reduce(function(sum, elem) {
      return sum + elem;
    }, 0);

    let flatenned = [];
    if(cells[0]){
    for(let i = 0; i < cells.length - 1; i++) {
      flatenned[i] = Object.values(cells[i]);
     }
    }
    function hoverOn(id) {
      if(id === (i + 'r')){
        setPercent(i + 'r');
      }
    }
    function hoverOff() {
      setPercent('');
    }
    function activOn(e) {
      setNearest(e.textContent);
      setActiv('activ');
    }
    function activOff() {
      setActiv('');
    }
    const row = Array.from({length: columns}).map((item, index) => {
      const color = `red ${1 +'%'}`;
      const transparent = Math.round((cellVal[index]/result) * 100);
      const classNameCell = activ;
      return(
  (percent === i + 'r') ? (
  <td style={{background: `linear-gradient(to bottom, Transparent ${100 - transparent}%, ${color})`}}
  key={index}>{transparent +'%'}</td>)
 : (<td key={index}
   id={i + 'c' + index} className= {(flatenned.flat().sort((x, y) => Math.abs(+nearest - x)
  - Math.abs(+nearest - y)).slice(0, near).some(currentVal => currentVal === cellVal[index]))
   ? (classNameCell) : ''}
   onClick={(e) => incr(e.target.id)}
  onMouseEnter={(e) => activOn(e.target)}
  onMouseLeave={activOff}>{cellVal[index]}</td>)
      )
    });
    return(
      <tr key={i}>
        {row}
        <td id={i + 'r'} className="tableRes"
       onMouseEnter={(e) => hoverOn(e.target.id)}
       onMouseLeave={hoverOff}>
         {result}</td>
         <td className="tableEnd">
         <Button variant="contained" color="secondary"
       id={i} onClick={() => onDelete(i)}>Delete</Button>
        </td>
      </tr>
    )
  }

  return (
    <div className="table-wrap">
      <span id="goBack-btn">
      <Button variant="contained" color="primary" onClick={history.goBack}>Back</Button>
      </span>
      <span>
        <table className="Mtable" aria-label="simple table">
          <tbody>
          {[...cells.slice(0, -1)].map(
              renderCell
            )}
          </tbody>
            <tfoot>
            {[...cells.slice(-1)].map((cell, i) =>
              <Footer key={i} cell={cell} />
            )}
            </tfoot>
        </table>
      </span>
    </div>
  );
}

export default Table;
